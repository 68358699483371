import React, { useState } from 'react'
import mainlogo from '../assets/trident_Logo.png'
import { useRef } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import starmenu from '../assets/wheel-tire.svg'
// 
import taxiservice from "../assets/taxiservice.png"
import tireservice from "../assets/tiresservice.png"
import haulageservice from "../assets/truck.png"
// 
// import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

function Navbar() {
    const navigate = useNavigate()
    const [navControl, setnavControl] = useState(true)
    const [sideInsideopen, setsideInsideopen] = useState(true)
    const sideref = useRef()
    const menulogoref = useRef()
    const menulogoref2 = useRef()
    const sideinside = useRef()

    function openNav() {
        if (navControl) {
            sideref.current.style.height = "100vh";
            sideref.current.style.borderRadius = "0%";
            menulogoref.current.style.rotate = '360deg';
            menulogoref2.current.style.rotate = '360deg';
        } else {
            sideref.current.style.height = "0";
            sideref.current.style.borderRadius = "0% 0% 100% 100%";
            menulogoref.current.style.rotate = '0deg';
            menulogoref2.current.style.rotate = '0deg';
        }

    }
    function sideInsideopenfunc(){
        if(sideInsideopen){
            sideinside.current.style.height = '175px';
   
        }else{
            sideinside.current.style.height = '0px';
        }
        
       
    }
    function homeClick(){
        navigate('/')
        window.scrollTo(0,0)
    }

    return (
        <>
            {/* nav bar */}
            <nav class="navbar navbar-expand-lg bg-body-tertiary">
                <div class="container-fluid p-0 navbar-inner">
                    <div className='ms-4'>
                        <NavLink class="navbar-brand" to='/'>
                            <img src={mainlogo} className="mainlogo" alt=""  data-aos="fade-in" data-aos-easing="ease-in-sine"
                        data-aos-duration="800"/>
                        </NavLink>
                        <div className="container" style={{cursor:"pointer"}} onClick={()=>{homeClick()}}>
                        {/* <h1 className='ms-4' style={{ fontWeight: "bold", color: "#4169E1"}} data-aos="fade-in" data-aos-easing="ease-in-sine"
                        data-aos-duration="800">Trident</h1> */}
                        </div>
                        {/* <NavLink to='/' style={{textDecoration:'none'}}>
                        <h1 className='ms-4' style={{ fontWeight: "bold", color: "#4169E1"}} data-aos="fade-in" data-aos-easing="ease-in-sine"
                        data-aos-duration="800">Trident</h1>
                        </NavLink> */}
                    </div>


                    <div className="d-flex menustarcontain">
                        <div className='menustar me-4' onClick={() => { openNav(); setnavControl(!navControl) }}>

                            <p><span> <img width="25px" className='starimg' ref={menulogoref} src={starmenu} alt="" /></span>{navControl ? " Menu" : " Close"}</p>
                        </div>

                    </div>
                    <div class="navlinks" id="navbarNavAltMarkup" data-aos="fade-left" data-aos-easing="ease-in-sine"
                        data-aos-duration="500">
                        <div class="navbar-nav">
                            <NavLink className={({ isActive }) => [isActive ? 'nav-link navlinksactive' : 'nav-link']} to='/' >Home</NavLink>
                            <NavLink className={({ isActive }) => [isActive ? 'nav-link navlinksactive' : 'nav-link']} to='/about'>About</NavLink>
                            <div className="dropdown">
                                <NavDropdown title="Services" style={{ color: "#4169E1 !important" }} id="nav-dropdown">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <NavDropdown.Item eventKey="4.1">
                                                    <NavLink to='/services/haulageservices' style={{ textDecoration: 'none', color: 'black' }}>
                                                        <div className="container">
                                                            <div className='flexcenter'>
                                                                <img width="45px" src={haulageservice} alt="" />
                                                            </div>
                                                            <h3 className='text-center'>Haulage</h3>
                                                        </div>
                                                    </NavLink>
                                                </NavDropdown.Item>
                                            </div>
                                            <div className="col-md-4">
                                                <NavDropdown.Item eventKey="4.2">
                                                    <NavLink to='/services/taxiservice' style={{ textDecoration: 'none', color: 'black' }}>
                                                        <div className="container">
                                                            <div className="flexcenter">
                                                                <img width="35px" src={taxiservice} alt="" />
                                                            </div>


                                                            <h3 className='text-center'>Taxi</h3>
                                                        </div>
                                                    </NavLink>
                                                </NavDropdown.Item>
                                            </div>
                                            <div className="col-md-4">
                                                <NavDropdown.Item eventKey="4.3">
                                                    <NavLink to='/services/tyresales' style={{ textDecoration: 'none', color: 'black' }}>
                                                        <div className="container">
                                                            <div className="flexcenter">
                                                                <img width="35px" src={tireservice} alt="" />
                                                            </div>

                                                            <h3 className='text-center'>Tyre</h3>
                                                        </div>
                                                    </NavLink>
                                                </NavDropdown.Item>
                                            </div>
                                        </div>
                                    </div>
                                </NavDropdown>
                            </div>
                            <NavLink className={({ isActive }) => [isActive ? 'nav-link navlinksactive' : 'nav-link']} to='/contact'>Contact</NavLink>
                        </div>
                    </div>
                </div>
            </nav>
            {/*  */}


            {/* navbar */}
            {/* sidenav */}
            <div ref={sideref} className="sidenav">
                <div className='closebtn' onClick={() => { openNav(); setnavControl(!navControl) }}>

                    <p><span><img width="25px" className='starimg' ref={menulogoref2} src={starmenu} alt="" /></span> Close</p>
                </div>
                {/* <div class="closebtn" onClick={() => { openNav(); setnavControl(!navControl) }}>&times;</div> */}
                <div className="container mt-5 pt-5">
                    <NavLink className={({ isActive }) => [isActive ? 'Sidenavlinks sidenavlinksactive' : 'Sidenavlinks']} to='/' onClick={() => { openNav(); setnavControl(!navControl) }}>Home</NavLink>
                    <NavLink className={({ isActive }) => [isActive ? 'Sidenavlinks sidenavlinksactive' : 'Sidenavlinks']} to='/about' onClick={() => { openNav(); setnavControl(!navControl)}}>About</NavLink>
                    <p className='Sidenavlinks mb-0'  onClick={()=>{sideInsideopenfunc();setsideInsideopen(!sideInsideopen)}}>Services &nbsp;<i class={!sideInsideopen?"arrow down":"arrow"} ></i></p>
                    <div ref={sideinside} className="sidenavopen" >
                        <NavLink className={({ isActive }) => [isActive ? 'Sidenavlinks sidenavlinksactive' : 'Sidenavlinks']} to='/services/haulageservices' onClick={() => { openNav(); setnavControl(!navControl);sideInsideopenfunc();setsideInsideopen(!sideInsideopen) }}>Haulage Service</NavLink>
                        <NavLink className={({ isActive }) => [isActive ? 'Sidenavlinks sidenavlinksactive' : 'Sidenavlinks']} to='/services/taxiservice' onClick={() => { openNav(); setnavControl(!navControl) ;sideInsideopenfunc();setsideInsideopen(!sideInsideopen) }}>Taxi Service</NavLink>
                        <NavLink className={({ isActive }) => [isActive ? 'Sidenavlinks sidenavlinksactive' : 'Sidenavlinks']} to='/services/tyresales' onClick={() => { openNav(); setnavControl(!navControl);sideInsideopenfunc();setsideInsideopen(!sideInsideopen) }}>Tyre Sales</NavLink>

                    </div>
                    <NavLink className={({ isActive }) => [isActive ? 'Sidenavlinks sidenavlinksactive' : 'Sidenavlinks']} to='/contact' onClick={() => { openNav(); setnavControl(!navControl) }}>Contact</NavLink>
                </div>

            </div>

            {/*  */}

        </>

    )
}

export default Navbar