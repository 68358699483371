import React, { useEffect } from 'react'
import './App.css'
import { Routes, Route} from 'react-router-dom'
import Home from './pages/Home'
import About from './pages/About'
import Services from './pages/Services'
import Contact from './pages/Contact'
import Tyrepage from './pages/Tyrepage'
import Deliverypage from './pages/Deliverypage'
import Taxipage from './pages/Taxipage'
// 
import AOS from "aos";
import "aos/dist/aos.css";
import Pagenotfound from './pages/Pagenotfound'
import PageLayout from './pages/PageLayout'
// import Message from './pages/Message'


function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <div className='canceloverflow'>

       
        <Routes>
          <Route element={<PageLayout></PageLayout>}>
            <Route path='/' element={<Home></Home>} />
            <Route path='/about' element={<About></About>} />
            <Route path='/services' element={<Services></Services>} />
            <Route path='/contact' element={<Contact></Contact>} />
            <Route path='/services/tyresales' element={<Tyrepage></Tyrepage>} />
            <Route path='/services/haulageservices' element={<Deliverypage></Deliverypage>} />
            <Route path='/services/taxiservice' element={<Taxipage></Taxipage>} />
            {/* <Route path='/messagepage' element={<Message></Message>} /> */}
            
          </Route>

          <Route path='*' element={<Pagenotfound></Pagenotfound>} />
        </Routes>
       

      </div>
    </>


  )
}

export default App